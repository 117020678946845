import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/NavbarRV"
import PageBanner from '../components/Common/PageBannerRV'
import Footer from "../components/App/FooterRV"
import ContactForm from '../components/Contact/ContactFormRVValidation'
const Contact = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Contact" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Contact" 
            />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Contact