import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {
    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Demandez un devis</h2>
                    <p>Besoin de l’avis d’un Expert construction ? Expliquez nous votre problématique, nous vous répondrons sous 48 heures.</p>
                </div>
                <div>
                    <h3>Merci pour votre demande</h3>
                    <p>Nous vous répondrons dans les plus brefs délais.</p>
                </div>
            </div>
        </section>
    )
}

export default ContactForm